.emailAccountRegistration .termsCheckbox {
    width: 20px;
    height: 20px;
    appearance: none;
    border-radius: 6px;
    vertical-align: middle;
    border: 1px solid #64748B;
    background-color: #FFF;
    outline: none;
    cursor: pointer;    
    -webkit-appearance: none;  
}

.emailAccountRegistration .termsCheckbox:checked {
    background-color: #0075BF;
    appearance: auto;
    clip-path: circle(60% at 51% 50%);
    border: 1px solid #64748B;
    width: 20px;
    height: 20px;
    outline: none;
    cursor: pointer;   
}

@media (max-width: 786px) {
    .emailAccountRegistration .imageBox {
        display: none !important;
    }
    .emailAccountRegistration .textBoxArea {
        width: 100% !important;
    }
}

@media (max-width: 460px) {
    .emailAccountRegistration .fullName {
        width: 100% !important;
        background: #FFFFFF;
    }
    .emailAccountRegistration .signupText {
        display: none !important;
    }
    .emailAccountRegistration {
        background-color: #F1F5F9;
    }
    .emailAccountRegistration .wantTo {
        width: 100% !important;
        background: #FFFFFF;
    }
    .emailAccountRegistration .email {
        width: 100% !important;
        background: #FFFFFF;
    }
    .emailAccountRegistration .password {
        width: 100% !important;
        background: #FFFFFF;
    }
    .emailAccountRegistration .signupBtn {
        width: 100% !important;
    }
    .emailAccountRegistration .loginBtn {
        color: #5E70DD !important;
    }
    .emailAccountRegistration .loginBtnCtn {
        width: fit-content !important;
    }
    .emailAccountRegistration .termslable {
        font-family: Manrope, sans-serif !important;
    }
}

@media (min-width: 461px) {
    .emailAccountRegistration .imageBg {
        display: none !important;
    }
}